import moment from "moment";
import _ from "lodash";

function elementsWithTags(list, inclusiveTags, exclusiveTags) {
	if (list != null) {
		if (inclusiveTags != null && inclusiveTags.length != 0) {
			list = list.filter((item) => {
				// Filtering inclusive tags
				// ANy tags can be present
				let itemTagNames = item.tags.map((itemTag) => itemTag.name);

				let hasAnyTags = inclusiveTags.reduce((accumulator, tag) => {
					if (itemTagNames.indexOf(tag) != -1) {
						accumulator = true;
					}
					return accumulator;
				}, false);

				return hasAnyTags;
			});
		}

		if (exclusiveTags != null && exclusiveTags.length != 0) {
			list = list.filter((item) => {
				// Filtering exclusive tags
				// All tags must be present
				let itemTagNames = item.tags.map((itemTag) => itemTag.name);

				let hasTags = exclusiveTags.reduce((accumulator, tag) => {
					if (itemTagNames.indexOf(tag) == -1) {
						accumulator = false;
					}
					return accumulator;
				}, true);

				return hasTags;
			});
		}
		return list;
	} else {
		return [];
	}
}

function formatDate(date) {
	return moment(date).format("MMM YYYY");
}

/**
 * Checks if the haystack includes the needle, regardless of case
 * (applies toLowerCase() to needle and haystack)
 *
 * @param {*} needle
 * @param {*} haystack
 * @returns boolean or null if needle/haystack are null
 */
function normalizedStringContains(needle, haystack) {
	if (needle == null || haystack == null) {
		return null;
	}
	return haystack.toLowerCase().includes(needle.toLowerCase());
}

function skillMatchesSearchString(skill, searchString) {
	if (searchString == null) {
		return true;
	}

	let titleMatch = normalizedStringContains(searchString, skill.title_short)
		|| normalizedStringContains(searchString, skill.title_long);
	let descriptionMatch = normalizedStringContains(
		searchString,
		skill.description
	);

	if (titleMatch || descriptionMatch) {
		return true;
	}

	let tagMatch =
		(skill.tags ?? []).filter((tag) => {
			return normalizedStringContains(searchString, tag.name);
		}).length >= 1;

	let projectMatch =
		(skill.projects ?? []).filter((project) => {
			return (
				normalizedStringContains(searchString, project.title) ||
				normalizedStringContains(searchString, project.description)
			);
		}).length >= 1;

	/** Is there text in the position that matches the search string? */
	let positionsMatch =
		(skill.positions ?? []).filter((position) => {
			let positionMatch =
				normalizedStringContains(searchString, position.title) ||
				// NOTE: Don't compare against the position description
				/* When comparing against the description, I've been usually putting the various languages in the description as well.
				 */

				// normalizedStringContains(
				// 	searchString,
				// 	position.descriptionHTML
				// ) ||
				normalizedStringContains(searchString, position.status) ||
				normalizedStringContains(searchString, position.type) ||
				normalizedStringContains(searchString, position.category);

			let companyMatch = false;
			if (position.company != null) {
				companyMatch =
					normalizedStringContains(
						searchString,
						position.company.name
					) ||
					normalizedStringContains(
						searchString,
						position.company.description
					);
			}

			// Checking to see if position has a matching skill
			// ie. What else have I used with this thing I'm searching?
			let positionSkillMatch = false;

			return positionMatch || positionSkillMatch || companyMatch;
		}).length >= 1;

	return (
		titleMatch ||
		descriptionMatch ||
		tagMatch ||
		projectMatch ||
		positionsMatch
	);
}

export default {
	state: {
		filterTags: null,
		resumeSearchString: null,
	},
	getters: {
		resume_search_string: (state) => {
			return state.resumeSearchString;
		},

		resume_has_filter: (state) => {
			return (
				(state.filterTags != null && state.filterTags.length > 0) ||
				(state.resumeSearchString != null &&
					state.resumeSearchString != "")
			);
		},

		resume_skills: (state, getters) => {
			if (
				getters.GET_SKILLS_BY_CATEGORY.loaded &&
				getters.GET_SKILLS_BY_CATEGORY.data != null
			) {
				let keys = Object.keys(getters.GET_SKILLS_BY_CATEGORY.data);

				return keys
					.map((categoryKey) => {
						let title = categoryKey
							.replaceAll("-", " ")
							.replaceAll("_", " ")
							.toLowerCase()
							// Making text Title Case
							.split(" ")
							.map(function (word) {
								if (word.length > 0) {
									let firstChar = word[0];
									return word.replace(
										firstChar,
										firstChar.toUpperCase()
									);
								} else {
									return word;
								}
							})
							.join(" ");

						if (categoryKey == "" || categoryKey == null) {
							title = "Other";
						}

						// Filtering skills based on search string / global filter tags
						let skills = elementsWithTags(
							getters.GET_SKILLS_BY_CATEGORY.data[categoryKey],
							[],
							state.filterTags ?? []
						).filter((skill) => {
							return skillMatchesSearchString(
								skill,
								state.resumeSearchString
							);
						});

						return {
							category: categoryKey,
							title: title,
							skills: skills,
						};
					})
					.filter((skillSet) => {
						// Removing skill categories that are missing skills
						return skillSet.skills.length > 0;
					});
			} else {
				return [];
			}
		},

		// Positions

		resume_positions: (state, getters) => {
			return getters.GET_POSITIONS.data
				.map((position) => {
					let positionTags = {};

					// unifying tags under skill_tags
					position.skills.forEach((positionSkill) => {
						positionSkill.tags.forEach((positionSkillTag) => {
							positionTags[positionSkillTag.name] = true;
						});
					});

					position.skill_tags = Object.keys(positionTags);

					return position;
				})
				.filter((position) => {
					if (state.resumeSearchString == null) {
						return true;
					}

					return (
						normalizedStringContains(
							state.resumeSearchString,
							position.title
						) ||
						normalizedStringContains(
							state.resumeSearchString,
							position.descriptionHTML
						) ||
						normalizedStringContains(
							state.resumeSearchString,
							position.company.name
						) ||
						normalizedStringContains(
							state.resumeSearchString,
							position.company.description
						) ||
						(position.tags ?? []).filter((positionTag) => {
							return normalizedStringContains(
								state.resumeSearchString,
								positionTag.name
							);
						}).length >= 1 ||
						(position.skill_tags ?? []).filter(
							(positionSkillTag) => {
								return normalizedStringContains(
									state.resumeSearchString,
									positionSkillTag
								);
							}
						).length >= 1 ||
						(position.skills ?? []).filter((positionSkill) => {
							return (
								normalizedStringContains(
									state.resumeSearchString,
									positionSkill.title_short
								) ||
								normalizedStringContains(
									state.resumeSearchString,
									positionSkill.title_long
								) ||
								normalizedStringContains(
									state.resumeSearchString,
									positionSkill.description
								)
							);
						}).length >= 1
					);
				});
		},
		resume_positions_software: (state, getters) => {
			let positions = getters.resume_positions.filter((position) => {
				if (
					position.category != "software" ||
					position.type != "employee"
				) {
					return false;
				}

				// Any tags include state.filterTags
				if (state.filterTags != null && state.filterTags.length > 0) {
					return (
						state.filterTags.findIndex((filterTag) => {
							return position.skill_tags.indexOf(filterTag) != -1;
						}) != -1
					);
				}

				return true;
			});

			return positions;
		},

		resume_positions_other: (state, getters) => {
			let positions = getters.resume_positions.filter((position) => {
				if (
					position.category == "software" ||
					position.type != "employee"
				) {
					return false;
				}

				// Any tags include state.filterTags
				if (state.filterTags != null && state.filterTags.length > 0) {
					return (
						state.filterTags.findIndex((filterTag) => {
							return position.skill_tags.indexOf(filterTag) != -1;
						}) != -1
					);
				}

				return true;
			});

			return positions;
		},
		resume_positions_volunteer: (state, getters) => {
			let positions = getters.resume_positions.filter((position) => {
				if (position.type != "volunteer") {
					return false;
				}

				// Any tags include state.filterTags
				if (state.filterTags != null && state.filterTags.length > 0) {
					return (
						state.filterTags.findIndex((filterTag) => {
							return position.skill_tags.indexOf(filterTag) != -1;
						}) != -1
					);
				}

				return true;
			});

			return positions;
		},

		resume_positions_education: (state, getters) => {
			let positions = getters.resume_positions
				.filter((position) => {
					if (position.type != "student") {
						return false;
					}

					// Any tags include state.filterTags
					if (
						state.filterTags != null &&
						state.filterTags.length > 0
					) {
						return (
							state.filterTags.findIndex((filterTag) => {
								return (
									position.skill_tags.indexOf(filterTag) != -1
								);
							}) != -1
						);
					}

					return true;
				});

			return positions;
		},

		resume_positions_education_software: (state, getters) => {
			return getters.resume_positions_education.filter((position) => {
				return position.category == "software";
			});
		},

		resume_positions_education_other: (state, getters) => {
			return getters.resume_positions_education.filter((position) => {
				// Should be not in the other categories (ie. software)
				return position.category != "software";
			});
		},

		// Resume Projects

		resume_projects: (state, getters) => {
			if (getters.GET_PROJECTS.loaded) {
				return getters.GET_PROJECTS.data
					.map((project) => {
						let projectTags = {};

						// Standardizing project skill tags

						project.skills.forEach((projectSkill) => {
							projectSkill.tags.forEach((projectSkillTag) => {
								projectTags[projectSkillTag.name] = true;
							});
						});

						project.skill_tags = Object.keys(projectTags);

						return project;
					})
					.filter((project) => {
						if (state.resumeSearchString == null) {
							return true;
						}

						return (
							normalizedStringContains(
								state.resumeSearchString,
								project.title
							) ||
							normalizedStringContains(
								state.resumeSearchString,
								project.description
							) ||
							project.skills.filter((projectSkill) => {
								return (
									normalizedStringContains(
										state.resumeSearchString,
										projectSkill.title
									) ||
									normalizedStringContains(
										state.resumeSearchString,
										projectSkill.description
									)
								);
							}).length >= 1 ||
							project.skill_tags.filter((projectSkillTag) => {
								return normalizedStringContains(
									state.resumeSearchString,
									projectSkillTag
								);
							}).length >= 1
						);
					});
			} else {
				return [];
			}
		},

		// Resume Awards

		resume_awards: (state, getters) => {
			if (getters.GET_AWARDS.loaded) {
				return getters.GET_AWARDS.data
					.map((award) => {
						let awardTags = {};

						// Standardizing project skill tags

						if (award.skills != null) {
							award.skills.forEach((awardSkill) => {
								if (awardSkill.tags != null) {
									awardSkill.tags.forEach((awardSkillTag) => {
										awardTags[awardSkillTag.name] = true;
									});
								}
							});
						}

						award.skill_tags = Object.keys(awardTags);

						return award;
					})
					.filter((award) => {
						// Any tags include state.filterTags
						let hasFilterTag = true;
						if (
							state.filterTags != null &&
							state.filterTags.length > 0
						) {
							if (award.skill_tags.length > 0) {
								hasFilterTag =
									state.filterTags.findIndex((filterTag) => {
										return (
											award.skill_tags.indexOf(
												filterTag
											) != -1
										);
									}) != -1;
							} else {
								hasFilterTag = false;
							}
						}

						return hasFilterTag;
					})
					.filter((award) => {
						if (state.resumeSearchString == null) {
							return true;
						}
						return (
							normalizedStringContains(
								state.resumeSearchString,
								award.title
							) ||
							normalizedStringContains(
								state.resumeSearchString,
								award.description
							) ||
							normalizedStringContains(
								state.resumeSearchString,
								award.company.title
							) ||
							normalizedStringContains(
								state.resumeSearchString,
								award.company.name
							) ||
							award.skills.filter((awardSkill) => {
								return (
									normalizedStringContains(
										state.resumeSearchString,
										awardSkill.title
									) ||
									normalizedStringContains(
										state.resumeSearchString,
										awardSkill.description
									)
								);
							}).length >= 1 ||
							award.skill_tags.filter((awardSkillTag) => {
								return normalizedStringContains(
									state.resumeSearchString,
									awardSkillTag
								);
							}).length >= 1
						);
					});
			} else {
				return [];
			}
		},
	},
	mutations: {
		RESUME_UPDATE_SEARCH_STRING: (state, string) => {
			state.resumeSearchString = string;
		},
	},
	actions: {
		RESUME_FILTER_CLEAR: ({ state }) => {
			state.filterTags = null;
			state.resumeSearchString = "";
		},

		RESUME_FILTER_BY_TAG: ({ state, getters, dispatch }, tags) => {
			state.filterTags = tags;
			dispatch("CREATE_METRIC_DEBOUNCED", {
				key: "resume search by tag",
				value: tags,
			});
		},

		RESUME_SET_SEARCH_STRING: ({ dispatch, commit }, string) => {
			commit("RESUME_UPDATE_SEARCH_STRING", string);

			dispatch("CREATE_METRIC_DEBOUNCED", {
				key: "resume search by string",
				value: string,
			});
		},
	},
};
