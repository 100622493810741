import httpService from "@/services/http-service";
import Vue from "vue";
import _ from "lodash";
import moment from "moment";

class apiResourceStoreFactory {
	actions = {};
	state = {};
	mutations = {};
	getters = {};

	baseApiUrl = null;

	storeKey = {
		// singular: "TEST",
		// plural: "TESTS"
	};

	constructor(storeKeySingular, storeKeyPlural, baseApiUrl) {
		this.storeKey.singular = storeKeySingular.toUpperCase();
		this.storeKey.plural = storeKeyPlural.toUpperCase();
		this.baseApiUrl = baseApiUrl;
	}

	/**
	 * The default state object for a response
	 */
	initStateResponseObj() {
		return {
			loading: false,
			loaded: false,
			data: null,
			error: null,
		};
	}

	buildState() {
		this.state[`GET_${this.storeKey.plural}`] = this.initStateResponseObj();
		this.state[`GET_${this.storeKey.singular}`] =
			this.initStateResponseObj();
		this.state[`CREATE_${this.storeKey.singular}`] =
			this.initStateResponseObj();
		this.state[`CREATE_${this.storeKey.plural}`] =
			this.initStateResponseObj();
		this.state[`UPDATE_${this.storeKey.plural}`] =
			this.initStateResponseObj();
		this.state[`UPDATE_${this.storeKey.singular}`] =
			this.initStateResponseObj();

		return this.state;
	}

	buildGetters() {
		let getterKeys = [
			`GET_${this.storeKey.singular}`,
			`GET_${this.storeKey.plural}`,
			`CREATE_${this.storeKey.singular}`,
			`CREATE_${this.storeKey.plural}`,
			`UPDATE_${this.storeKey.singular}`,
			`UPDATE_${this.storeKey.plural}`,
		];

		getterKeys.forEach((getterKey) => {
			this.getters[getterKey] = (state) => {
				return state[getterKey];
			};
		});
	}

	getApiRequest(
		state,
		dispatch,
		commit,
		dispatchKey,
		path,
		forceLoad = false
	) {
		commit("REQUEST_STATE", { loading: true, key: dispatchKey });

		return new Promise((resolve, reject) => {
			if (state[dispatchKey].loaded == true && !forceLoad) {
				commit("REQUEST_STATE", { loading: false, key: dispatchKey });
				resolve(state[dispatchKey].data);
			} else {
				httpService
					.get(path)
					.then((response) => {
						commit("REQUEST_STATE", {
							key: dispatchKey,
							loading: false,
							loaded: true,
							data: response.data,
						});
						resolve(state[dispatchKey].data);
					})
					.catch((error) => {
						commit("REQUEST_STATE", {
							key: dispatchKey,
							loading: false,
							loaded: false,
							error: error,
						});

						dispatch("SET_POPUP", {
							message: error.message
								? error.message
								: "There was an error with your request.",
							color: "error",
						});

						reject(error);
					});
			}
		});
	}

	postApiRequest(
		state,
		dispatch,
		commit,
		dispatchKey,
		path,
		params,
		forceLoad = false
	) {
		state[dispatchKey].loading = true;
		return new Promise((resolve, reject) => {
			httpService
				.post(path, params)
				.then((response) => {
					commit("REQUEST_STATE", {
						key: dispatchKey,
						loading: false,
						loaded: true,
						data: response.data,
					});
					resolve(state[dispatchKey].data);
				})
				.catch((error) => {
					commit("REQUEST_STATE", {
						key: dispatchKey,
						loading: false,
						loaded: false,
						error: error,
					});

					dispatch("SET_POPUP", {
						message: error.message
							? error.message
							: "There was an error with your request.",
						color: "error",
					});

					reject(error);
				});
		});
	}

	patchApiRequest(
		state,
		dispatch,
		commit,
		dispatchKey,
		path,
		params,
		forceLoad = false
	) {
		commit("REQUEST_STATE", {
			key: dispatchKey,
			loading: true,
		});

		return new Promise((resolve, reject) => {
			httpService
				.patch(path, params)
				.then((response) => {
					commit("REQUEST_STATE", {
						key: dispatchKey,
						loading: false,
						loaded: true,
						data: response.data,
					});
					resolve(state[dispatchKey].data);
				})
				.catch((error) => {
					commit("REQUEST_STATE", {
						key: dispatchKey,
						loading: false,
						loaded: false,
						error: error,
					});

					dispatch("SET_POPUP", {
						message: error.message
							? error.message
							: "There was an error with your request.",
						color: "error",
					});

					reject(error);
				});
		});
	}

	buildActions() {
		// Get list of all objects.
		// Maps to Controller@index
		this.actions[`GET_${this.storeKey.plural}`] = _.debounce(
			({ dispatch, commit }, data) => {
				let forceLoad = data ? data.force : false;
				let path = this.baseApiUrl;
				let dispatchKey = `GET_${this.storeKey.plural}`;

				return this.getApiRequest(
					this.state,
					dispatch,
					commit,
					dispatchKey,
					path,
					forceLoad
				);
			},
			500,
			{
				leading: true,
				trailing: false,
			}
		);

		// Get single object.
		// Maps to Controller@show
		this.actions[`GET_${this.storeKey.singular}`] = _.debounce(
			({ dispatch, commit }, data) => {
				let dispatchKey = `GET_${this.storeKey.singular}`;

				let resourceId = data.id;

				if (resourceId == null) {
					throw Error(dispatchKey + ": ID not defined");
				}

				let forceLoad = data.force;
				let path = this.baseApiUrl
					+ (
						// Making sure there is a '/' between the base URL and the resource ID
						this.baseApiUrl.slice(-1) == "/"
						? ""
						: "/"
					)
					+ resourceId;

				commit("REQUEST_STATE", {
					key: dispatchKey,
					resourceId: resourceId,
					loading: true,
				});

				return new Promise((resolve, reject) => {
					if (
						this.state[dispatchKey][resourceId].loaded == true &&
						!forceLoad
					) {
						commit("REQUEST_STATE", {
							key: dispatchKey,
							resourceId: resourceId,
							loading: false,
						});
						resolve(this.state[dispatchKey][resourceId].data);
					} else {
						httpService
							.get(path)
							.then((response) => {
								commit("REQUEST_STATE", {
									key: dispatchKey,
									resourceId: resourceId,
									loading: false,
									loaded: true,
									data: response.data,
								});
								resolve(
									this.state[dispatchKey][resourceId].data
								);
							})
							.catch((error) => {
								commit("REQUEST_STATE", {
									key: dispatchKey,
									resourceId: resourceId,
									loading: false,
									loaded: false,
									error: error,
								});

								dispatch("SET_POPUP", {
									message: error.message
										? error.message
										: "There was an error with your request.",
									color: "error",
									show: true,
								});

								reject(error);
							});
					}
				});
			},
			500,
			{
				leading: true,
				trailing: false,
			}
		);

		this.actions[`CREATE_${this.storeKey.singular}`] = (
			{ dispatch, commit },
			resource
		) => {
			let path = this.baseApiUrl;
			let dispatchKey = `CREATE_${this.storeKey.singular}`;

			return this.postApiRequest(
				this.state,
				dispatch,
				commit,
				dispatchKey,
				path,
				resource
			);
		};

		this.actions[`CREATE_${this.storeKey.singular}_DEBOUNCED`] = _.debounce(
			({ dispatch, commit }, resource) => {
				let path = this.baseApiUrl;
				let dispatchKey = `CREATE_${this.storeKey.singular}`;

				return this.postApiRequest(
					this.state,
					dispatch,
					commit,
					dispatchKey,
					path,
					resource
				);
			},
			1000,
			{
				leading: false,
				trailing: true
			}
		);

		// Update Object
		// Maps to Controller@update
		this.actions[`UPDATE_${this.storeKey.singular}`] = (
			{ dispatch, commit },
			resource
		) => {
			let dispatchKey = `UPDATE_${this.storeKey.singular}`;

			if (resource.id == null) {
				throw Error(dispatchKey + ": ID not defined");
			}

			let path = this.baseApiUrl + resource.id;

			return this.patchApiRequest(
				this.state,
				dispatch,
				commit,
				dispatchKey,
				path,
				resource
			);
		};

		// Update Object
		// Maps to Controller@update
		this.actions[`UPDATE_ALL_${this.storeKey.plural}`] = (
			{ dispatch, commit },
			resource
		) => {
			let path = this.baseApiUrl + "all";
			let dispatchKey = `UPDATE_${this.storeKey.plural}`;

			return this.patchApiRequest(
				this.state,
				dispatch,
				commit,
				dispatchKey,
				path,
				resource
			);
		};

		// Delete Object
		// Maps to Controller@delete
		this.actions[`DELETE_${this.storeKey.singular}`] = (
			{ dispatch },
			resourceId
		) => {
			let dispatchKey = `DELETE_${this.storeKey.singular}`;

			if (resourceId == null) {
				throw Error(dispatchKey + ": ID not defined");
			}

			let path = this.baseApiUrl + resourceId;

			return new Promise((resolve, reject) => {
				httpService
					.delete(path)
					.then((response) => {
						resolve(response.data);
					})
					.catch((error) => {
						dispatch("SET_POPUP", {
							message: error.message
								? error.message
								: "There was an error with your request.",
							color: "error",
						});

						reject(error);
					});
			});
		};

		// Delete All objectis
		// Maps to Controller@delete
		this.actions[`DELETE_ALL_${this.storeKey.plural}`] = ({ dispatch }) => {
			let dispatchKey = `DELETE_ALL_${this.storeKey.plural}`;

			let path = this.baseApiUrl + "all";

			return new Promise((resolve, reject) => {
				httpService
					.delete(path)
					.then((response) => {
						resolve(response.data);
					})
					.catch((error) => {
						dispatch("SET_POPUP", {
							message: error.message
								? error.message
								: "There was an error with your request.",
							color: "error",
						});

						reject(error);
					});
			});
		};
	}

	buildNestedActions() {
		// Get list of all objects.
		// Maps to Controller@index
		this.actions[`GET_${this.storeKey.plural}`] = _.debounce(
			({ dispatch, commit }, data) => {
				let forceLoad = data ? data.force : false;
				let path = this.baseApiUrl;
				let dispatchKey = `GET_${this.storeKey.plural}`;

				commit("REQUEST_STATE", {
					key: dispatchKey,
					loading: true,
				});

				return new Promise((resolve, reject) => {
					if (this.state[dispatchKey].loaded == true && !forceLoad) {
						resolve(this.state[dispatchKey].data);
					} else {
						httpService
							.get(path)
							.then((response) => {
								commit("REQUEST_STATE", {
									key: dispatchKey,
									loading: false,
									loaded: true,
									data: response.data,
								});
								resolve(this.state[dispatchKey].data);
							})
							.catch((error) => {
								commit("REQUEST_STATE", {
									key: dispatchKey,
									loading: false,
									loaded: false,
									error: error,
								});

								dispatch("SET_POPUP", {
									message: error.message
										? error.message
										: "There was an error with your request.",
									color: "error",
								});

								reject(error);
							});
					}
				});
			},
			500,
			{
				leading: true,
				trailing: false,
			}
		);
	}

	buildMutations() {
		/**
		 * Updates the request state
		 * Payload = [
		 * 		key => request key (ex. GET_PROJECTS)
		 * 		...key/value pairs of props in the initStateResponseObj
		 * ]
		 */
		this.mutations["REQUEST_STATE"] = (state, payload) => {
			let requestKey = payload["key"];
			let resourceId = payload["resourceId"];
			let newState = state;

			// Init state if missing
			if (newState[requestKey] == null) {
				newState[requestKey] = this.initStateResponseObj();
			}

			// Getting state
			let requestState = newState[requestKey];
		
			if (resourceId != null) {
				requestState = requestState[resourceId];
			}

			if(requestState == null){
				requestState = this.initStateResponseObj();
			}

		
			Object.keys(payload)
				.filter((key) => {
					return Object.keys(requestState).includes(key);
				})
				.forEach((key) => {
				if (Object.keys(requestState).includes(key)) {
					requestState[key] = payload[key];
				} else {
					// NOTE: 'key' is used to define the request key. Ignoring it automatically
					if (key != "key") {
						console.warn(
							`Key "${key}" not found in Request State object. Stop trying to update the key, or add it to the initStateResponseObj`,
						);
					}
				}
			});

			if (resourceId != null) {
				newState[requestKey][resourceId] = requestState;
			} else {
				newState[requestKey] = requestState;
			}

			state = newState;
		};
	}

	getStore() {
		this.buildState();
		this.buildGetters();
		this.buildActions();
		this.buildMutations();

		let store = {
			state: this.state,
			getters: this.getters,
			actions: this.actions,
			mutations: this.mutations,
		};

		return store;
	}
}

export default apiResourceStoreFactory;

