<template>
	<div>
		<v-parallax
			:src="require(`@/assets${c_imgSrc}`)"
			>
			<div
				class="d-flex d-flex-row justify-space-between align-center flex-nowrap"
				>
				<v-spacer />
				<div class="outer-content">
					<div class="inner-content">
						<AboutMeCarouselTitle :title="title" :subtitle="subtitle" />
						<!--
							TODO: Add in ability to add image here
							On home page, add in your face
						-->
					</div>
				</div>
				<v-spacer />
			</div>
		</v-parallax>
	</div>
</template>
<script>

import AboutMeCarouselTitle from "@/components/about-me-carousel/items/AboutMeCarouselTitle.vue";

export default {
	components: { AboutMeCarouselTitle },
	props: {
		imgSrc: {
			required: false,
			default: '@/assets/code-background.jpg'
		},
		title: {
			required: true
		},
		subtitle: {
			required: false
		}
	},
	data() {
		return {
			/**
			NOTE: This path must match the text in the v-parallax :src 

			For some reason, the '@/assets' MUST be a string inside of the :src path; it cannot be a variable.
			Because it can't be in a variable, I've had to hard code it...

			PS. This is really fucking dumb, and I hate it
			*/
			basepath: "@/assets",
		}
	},
	computed:{
		c_imgSrc: function(){
			// Removes the beginning part of the path so the img will load
			let substr = this.imgSrc.substr(this.basepath.length);

			return substr;
		}
	}
}
</script>
<style>
.outer-content{
	backdrop-filter: blur(20px);
	background-color: none;
	box-shadow: none !important;

	text-align: center;

	max-width: 50em;
}
.inner-content {
	border: solid;
	border-radius: 5px;

	margin: 1em;
	padding: 5em;

}
</style>