<template>
	<p class="text-center">
		Interested in working together?
		<br />
		<v-btn color="secondary" :href="'mailto:' + email" small outlined>
			Send me an Email
		</v-btn>
	</p>
</template>

<script>
export default {
	data() {
		return {
			email: process.env.VUE_APP_CONTACT_EMAIL,
		};
	},
};
</script>

<style></style>
