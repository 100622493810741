<template>
	<v-app>
		<v-main>
			<router-view />
			<FooterComponent />
		</v-main>
	</v-app>
</template>

<script>
import httpService from "@/services/http-service";
import FooterComponent from "./components/footer/FooterComponent.vue";
export default {
	components: { FooterComponent },
	name: "App",

	data: () => ({
		//
	}),

	beforeMount() {
		this.getCsrfToken()
			.then(response => {
				this.pingReferenceLink();
			});
	},

	methods: {
		getCsrfToken: function(){
			return httpService.getCsrfToken();
		},

		pingReferenceLink: function(){
			if(this.$route.query.ref != null){
				let refId = this.$route.query.ref;
				let contextKey = this.$route.query.context_key;
				this.$store.dispatch("UPDATE_LINK_REFERENCE", {
					id: refId,
					link_reference_id: refId,
					context_key: contextKey
				});
			}
		}
	},
};
</script>

<style></style>
