import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import awardsStore from "./awardsStore.js"
import companyStore from "./companyStore.js"
import configStore from "./configStore.js";
import debugStore from "./debugStore.js";
import latexResumeStore from "./latexResumeStore.js";
import metricsStore from "./metricsStore.js";
import playgroundDataStore from "./playgroundDataStore.js"
import popupStore from "./popupStore.js"
import positionsStore from "./positionsStore.js";
import postsStore from "./postsStore.js";
import projectsStore from "./projectsStore.js"
import referenceStore from "./referenceStore.js";
import resumeStore from "./resumeStore.js"
import skillsStore from "./skillsStore.js";

export default new Vuex.Store({
	state: {},
	getters: {},
	mutations: {},
	actions: {},
	modules: {
		awardsStore,
		companyStore,
		configStore,
		debugStore,
		latexResumeStore,
		metricsStore,
		playgroundDataStore,
		popupStore,
		positionsStore,
		postsStore,
		projectsStore,
		referenceStore,
		resumeStore,
		skillsStore,
	},
});
