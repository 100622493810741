<template>
	<div>
		<div
			v-if="$store.getters.GET_SKILLS.loaded"
			class="scroll-container d-flex flex-row justify-space-between my-10"
			id="logo-scroller-container"
		>
			<div v-for="(skill, index) in skills" :key="skill.id + '-' + index">
				<div v-if="skill.logoUrl" class="mx-8">
					<v-img
						:src="skill.logoUrl"
						height="5em"
						width="5em"
						contain
					/>
					<div class="text-center text-caption">
						{{ skill.title_long ?? skill.title_short }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			skills: [],
			autoScroll: true,
			scrollerElement: null,
			scrollerPos: null,
			scrollerSpeedDelay: 10,
			scrollerPosPercent: null,
			debug: true,
		};
	},
	mounted() {
		this.$store.dispatch("GET_SKILLS").then((skills) => {
			this.skills = skills.concat(skills);

			let elementId = "logo-scroller-container";
			this.scrollerElement = document.getElementById(elementId);

			this.runAutoScroll();
		});
	},
	methods: {
		runAutoScroll() {
			this.scrollerPosPercent =
				(this.scrollerElement.scrollLeft /
					this.scrollerElement.scrollWidth) *
				100;

			// After going through the list fully once, the scroller should snap back to the starting position
			if (this.scrollerPosPercent >= 50) {
				this.scrollerElement.scrollLeft = 0;
			}

			if (this.autoScroll) {
				this.scrollerElement.scrollBy(1, 0);
			}

			setTimeout(this.runAutoScroll, this.scrollerSpeedDelay);
		},
	},
};
</script>

<style>
.scroll-container {
	overflow-x: hidden;
}
</style>
