import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		options: {
			customProperties: true
		},
		dark: true,
		themes: {
			light: {
				primary: "#08046E",
				secondary: "#216E0F",
				accent: "#1F1ABA",
			},
			dark: {
				primary: "#7d9abd",
				secondary: "#5b9955",
				accent: "#e17542",

				// primary: "#da6a96",
				// secondary: "#5b9955",
				// accent: "#3178d6",
			}
		},
	},
});