<template>
	<p class="text-center">
		Interested in learning more?
		<br />
		<v-btn :to="{ name: 'resume' }" color="secondary" small outlined>
		Check out my Web Resume
		</v-btn>
	</p>
</template>

<script>
export default {};
</script>

<style></style>
