import httpService from "@/services/http-service";
import Vue from "vue";
import _ from "lodash";
import moment from "moment";

const popupStore = {
	state: {
		show: false,
		message: null,
		color: null
	},
	getters: {
		popup: (state) => {
			return state;
		},
	},
	// Use `dispatch` to run these items
	actions: {
		SET_POPUP: function ({state}, popup) {
			state.show = popup.show != null ? popup.show : true;
			state.message = popup.message;
			state.color = popup.color;
		},
	},
	// Use `commit` to use these items
	mutations: {
	},
};

export default popupStore;