<template>
	<TeaserCard
		:body="body"
		:title="title"
		:icons="['mdi-account-group']"
		:loading="this.$store.getters.GET_METRICS.loading"
	/>
</template>
<script>
import TeaserCard from "@/components/teasers/cards/TeaserCard.vue";
export default {
	components: {
		TeaserCard
	},
	data() {
		return {
			title: "Leadership",
		}
	},
	mounted() {
		this.$store.dispatch("GET_METRICS");
	},
	computed: {
		experience_software: function()
		{
			if(this.$store.getters.GET_METRICS.loaded == false){
				return "0 seconds";
			}
			else {
				return this.$store.getters.GET_METRICS.data.experience.leadership_software
			}
		},
		experience_all: function()
		{
			if(this.$store.getters.GET_METRICS.loaded == false){
				return "0 seconds";
			}
			else {
				return this.$store.getters.GET_METRICS.data.experience.leadership_all
			}
		},

		body: function()
		{
			return `I have been in leadership roles for ${this.experience_all}; ${this.experience_software} in software development leadership roles.`;
		}
	},
}
</script>
