import httpService from "@/services/http-service";

let debugStore = {
	state: [],
	getters: [],
	actions: {
		CACHE_CLEAR: () => {
			httpService.get("/api/v1/cache/clear");
		},
	},
	mutations: [],
};

export default debugStore;