<template>
	<div>
		<FadedIntroParallax />
		<v-container>

			<LogoScroller />

			<TeaserCardRow />

			<v-row>
				<v-col cols="6">
					<ResumeTeaser />
				</v-col>
				<v-col cols="6">
					<ContactTeaser />
				</v-col>
			</v-row>

			<v-row>
				<v-col class="d-flex justify-space-around">
					<AboutWebsiteTeaserCard />
				</v-col>
			</v-row>

			<v-row>
				<v-col cols="12">
					<GetStartedTeaser />
				</v-col>
			</v-row>

		</v-container>
	</div>
</template>

<script>
import ContactTeaser from "@/components/teasers/ContactTeaser.vue";
import FadedIntroParallax from "@/components/teasers/FadedIntroParallax.vue";
import GetStartedTeaser from "@/components/teasers/GetStartedTeaser.vue";
import LogoScroller from "@/components/misc/LogoScroller.vue";
import ResumeTeaser from "@/components/teasers/ResumeTeaser.vue";
import AboutWebsiteTeaserCard from "@/components/teasers/cards/AboutWebsiteTeaserCard.vue";
import TeaserCardRow from "@/components/teasers/cards/TeaserCardRow.vue";
export default {
	components: {
		ContactTeaser,
		FadedIntroParallax,
		GetStartedTeaser,
		LogoScroller,
		ResumeTeaser,
		AboutWebsiteTeaserCard,
		TeaserCardRow,
	},
	name: "Home",
	mounted() {
		// Preloading for resume page
		let ms = 1500;
		new Promise(resolve => setTimeout(resolve, ms))
			.then(res => {
				this.$store.dispatch("GET_SKILLS")
				this.$store.dispatch("GET_POSITIONS");
				this.$store.dispatch("GET_COMPANIES");
				this.$store.dispatch("GET_PROJECTS");
				this.$store.dispatch("GET_SKILLS_BY_CATEGORY");
				this.$store.dispatch("GET_AWARDS");
			});
	},
};
</script>