import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "@/views/HomeView.vue";
import PlainLayout from "@/layouts/PlainLayout.vue";
import PublicLayout from "@/layouts/PublicLayout.vue";
import store from "@/store"

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		component: PublicLayout,
		children: [
			{
				path: "/",
				name: "home",
				component: HomeView,
			},
			{
				path: "/about",
				name: "about",
				component: () =>
					import(
						/* webpackChunkName: "about" */ "@/views/AboutView.vue"
				),
			},
			{
				path: "/services",
				name: "services",
				component: () =>
					import(
						/* webpackChunkName: "services" */ "@/views/ServicesView.vue"
				),
			},
			{
				path: "/contact-me",
				name: "contact-me",
				component: () =>
					import(
						/* webpackChunkName: "contact-me" */ "@/views/ContactMeView.vue"
				),
			},
			{
				path: "/resume",
				component: PlainLayout,
				children: [
					{
						path: "/",
						name: "resume",
						component: () => import ("@/views/ResumeView.vue"),
					},
					{
						path: "skills",
						component: PlainLayout,
						children: [
							{
								path: '',
								name: "resume-skills",
								component: () => import ("@/views/resume/skills/SkillsView.vue"),
							},
							{
								path: ':skill_id',
								name: "resume-skill-details",
								component: () => import ("@/views/resume/skills/SkillView.vue"),
							}

						]
					}
				]
			},
			{
				path: "/playground",
				component: PlainLayout,
				children: [
					{
						path: "/",
						name: "playground-home",
						component: () => import("@/views/PlaygroundView.vue"),
					},
					{
						path: "registration",
						name: "playground-registration",
						component: () =>
							import(
								"@/components/playground/registration/PlaygroundRegistrationPage.vue"
							),
					},
					{
						path: "data",
						name: "playground-data",
						component: () =>
							import(
								"@/components/playground/data/PlaygroundDataPage.vue"
							),
					},
				],
			},
			{
				path: "/blog",
				component: PlainLayout,
				children: [
					{
						path: "/",
						name: "post-list-view",
						component: () => import ("@/views/blog/BlogView.vue"),
					},
					{
						path: ':post_id',
						name: "post-details-view",
						component: () => import ("@/views/blog/PostView.vue"),
					}
				]
			},

			{
				path: "/debug",
				name: "debug",
				component: () =>
					import(
						/* webpackChunkName: "debug" */ "@/views/DebugView.vue"
				),
			},
		],
	},
];

const router = new VueRouter({
	// mode: "history",
	base: process.env.BASE_URL,
	routes,
});

router.afterEach((to, from) => {

	// Logging page changes
	store.dispatch("CREATE_METRIC", {
		key: "page nav",
		value: to.path
	});

});

export default router;
