import apiResourceStoreFactory from "./apiResourceStoreFactory";

let storeFactory = new apiResourceStoreFactory(
	"SKILL",
	"SKILLS",
	"/api/v1/skills"
	// NOTE: Cannot have a / at the end
	// /api/v1/skills/?group-by=category = CORS error BECAUSe of the '/'... dunno why
);
let store = storeFactory.getStore();

function buildGetRequest(storeKey, path) {
	store.actions[storeKey] = ({ state, dispatch, commit }, props) => {
		let forceLoad =
			props != null && props.force != null ? props.force : false;
		storeFactory.getApiRequest(
			state,
			dispatch,
			commit,
			storeKey,
			storeFactory.baseApiUrl + path,
			forceLoad
		);
	};
	store.state[storeKey] = storeFactory.initStateResponseObj();
	store.getters[storeKey] = (state) => state[storeKey];
}

// ========== ACTIONS ========== //

buildGetRequest("GET_SKILLS_BY_CATEGORY", "?group-by=category");

// Skill by Slug
//
// TODO: Complete this

store.actions["GET_SKILL_BY_SLUG"] = ({ state, dispatch, commit }, props) => {
	let forceLoad =
		props != null && props.force != null ? props.force : false;

	let slug = props.slug;

	storeFactory.getApiRequest(
		state,
		dispatch,
		commit,
		"GET_SKILL_BY_SLUG",
		storeFactory.baseApiUrl + "/" + slug,
		forceLoad
	);
};
store.state["GET_SKILL_BY_SLUG"] = storeFactory.initStateResponseObj();
store.getters["GET_SKILL_BY_SLUG"] = (state) => state["GET_SKILL_BY_SLUG"];

// ========== GETTERS ========== //

/**
 * List of programming language skills
 * @returns
 */
store.getters.programmingLanguageSkills = (state) => {
	if (!state.GET_SKILLS.loaded) {
		return [];
	} else {
		return state.GET_SKILLS.data.filter((skill) => {
			// If there are no tags associated, filter out
			if (skill.tags.length == 0) {
				return false;
			}

			// Filtering in skills with tags
			return (
				skill.tags.filter((tag) => {
					return (
						tag.name == "Framework" ||
						tag.name == "Programming Language"
					);
				}).length >= 1
			);
		});
	}
};

store.getters.toolSkills = (state) => {
	if (!state.GET_SKILLS.loaded) {
		return [];
	} else {
		return state.GET_SKILLS.data.filter((skill) => {
			// If there are no tags associated, filter out
			if (skill.tags.length == 0) {
				return false;
			}

			// Filtering in skills with tags
			return (
				skill.tags.filter((tag) => {
					return (
						tag.name == "IaaS" ||
						tag.name == "Continuous Integration" ||
						tag.name == "Tool"
					);
				}).length >= 1
			);
		});
	}
};

export default store;
