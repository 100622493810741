<template>
	<v-card :max-width="max_width" width="100%">
		<div v-if="loading">
			<v-card-text class="text-center">
				<v-progress-circular indeterminate />
			</v-card-text>
		</div>
		<div v-else>
			<v-card-title
				class="d-flex justify-space-around"
				v-if="icons != null && icons.length > 0"
				>
				<v-icon
					v-for="icon in icons"
					:key="icon"
					x-large
					>
					{{icon}}
				</v-icon>
			</v-card-title>
			<v-card-title class="text-center d-flex justify-center primary--text">
				{{title}}
			</v-card-title>
			<v-card-text class="text-center" v-html="body">
			</v-card-text>
		</div>
	</v-card>
</template>
<script>
export default {
	props: {
		body: {
			required: false
		},
		icons: {
			required: false
		},
		title: {
			required: true
		},
		loading: {
			required: false,
			default: false
		},
		max_width: {
			required: false,
			default: "30em"
		}
	}
}
</script>
