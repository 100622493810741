import apiResourceStoreFactory from "./apiResourceStoreFactory";

let storeFactory = new apiResourceStoreFactory(
	"PLAYGROUND_DATUM",
	"PLAYGROUND_DATA",
	"/api/v1/playground/"
);
let store = storeFactory.getStore();

function buildGetRequest(storeKey, path) {
	store.actions[storeKey] = ({ state, dispatch, commit }) =>
		storeFactory.getApiRequest(
			state,
			dispatch,
			commit,
			storeKey,
			storeFactory.baseApiUrl + path,
			true
		);
	store.state[storeKey] = storeFactory.initStateResponseObj();
	store.getters[storeKey] = (state) => state[storeKey];
}

buildGetRequest("GET_UNOPTIMIZED_PLAYGROUND_DATA", "index/unoptimized");
buildGetRequest("GET_OPTIMIZED_PLAYGROUND_DATA", "index/cached");
buildGetRequest("GET_BETTER_CACHED_PLAYGROUND_DATA", "index/strategic-caching");
buildGetRequest("CLEAR_PLAYGROUND_DATA_INDEX_CACHE", "index/cache-clear");

export default store;
