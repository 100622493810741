<template>
	<v-row>
		<v-col cols="12" class="text-center">
			<span class="display-2">
				{{ teaserText }}
			</span>
		</v-col>
		<v-col cols="12" class="text-center">
			<v-btn
				class="accent--text font-weight-bold"
				x-large
				outlined
				:href="'mailto:' + email"
			>
				Contact Me!
			</v-btn>
		</v-col>
	</v-row>
</template>

<script>
export default {
	data() {
		return {
			email: process.env.VUE_APP_CONTACT_EMAIL,
			texts: ["Do you have a new project idea?", "Want to know more?"],
		};
	},
	computed: {
		teaserText: function () {
			return this.texts[Math.floor(Math.random() * this.texts.length)];
		},
	},
};
</script>

<style></style>
