<template>
	<div>
		<div>
			<!-- md and up -->
			<div class="display-3 d-none d-md-block" v-html="title" />
			<!-- xs to sm -->
			<div class="display-1 d-md-none" v-html="title" />
		</div>
		<v-card-subtitle v-if="subtitle">
			<div class="subtitle-1" v-html="subtitle" />
		</v-card-subtitle>
	</div>
</template>

<script>
export default {
	props: ["title", "subtitle"],
};
</script>

<style></style>
