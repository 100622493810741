<template>
	<div>
		<FadedParallax
			imgSrc="@/assets/code-background.jpg"
			:title="title"
			:subtitle="subtitle"
		/>
	</div>
</template>
<script>

import AboutMeCarouselTitle from "@/components/about-me-carousel/items/AboutMeCarouselTitle.vue";
import FadedParallax from "@/components/ui/FadedParallax.vue";
export default {
	components: {
		AboutMeCarouselTitle,
		FadedParallax
	},
	data() {
		return {
			title: "I'm Andrew Lampert.",
			subtitle: "I'm Andrew, a Full Stack Software Developer. I graduated with a degree in Engineering Sciences (Software). I've worked on projects from conception to long-term-service maintenance. I have experience with planning and managing software applications. My interests are in web development, optimization, and leadership."
		}
	},
	mounted() {
		this.$store.dispatch("GET_METRICS")
			.then((response) => {
				this.subtitle = response.summary;
				this.subtitle = this.subtitle.replace("Full Stack Software Developer", "<span class='accent--text'>Full Stack Software Developer</span>");
		});
		this.imgSrcPathMe =
			process.env.VUE_APP_BACKEND_URL + "/storage/me/headshot-2.jpg";
	},

}
</script>
<style>
.outer-content{
	backdrop-filter: blur(20px);
	background-color: none;
	box-shadow: none !important;

	text-align: center;

	max-width: 50em;
}
.inner-content {
	border: solid;
	border-radius: 5px;

	margin: 1em;
	padding: 5em;

}
</style>
