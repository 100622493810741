<template>
	<div>
		<navigation-bar />

		<router-view />

		<PopupComponent />
	</div>
</template>

<script>
import NavigationBar from "@/components/navigation/NavigationBar.vue";
import PopupComponent from '@/components/popup/PopupComponent.vue';
export default {
	name: "PublicLayout",
	components: { NavigationBar, PopupComponent },
};
</script>
