import axios from "axios";
import router from "@/router";

axios.defaults.withCredentials = true;
axios.defaults.headers.common["Access-Control-Allow-Credentials"] = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

var headers = {
	"Content-Type": "application/json",
	access_token: "",
};

var backendBaseUrl = process.env.VUE_APP_BACKEND_URL;

// ===== Interceptors ===== //

axios.interceptors.response.use(
	response => {
		return response;
	},
	error => {
		console.error(error.response);
		if(error.response){
			let errorData = error.response.data;

			switch (error.response.status) {
				case 500:
					errorData.message = "An unexpected error occured";
					break;
				default:
					// TODO
					break;
			}
			return Promise.reject(errorData);
		} else {
			switch(error.code){
				case "ERR_NETWORK":
					error.message = "Unable to connect to the network. Please check your connection and try again.";
					break;
			}
			return Promise.reject(error);
		}
	}
);

var httpService = {

	// ===== Requests ===== //
	
	getCsrfToken()
	{
		let url = this.cleanUrl("/sanctum/csrf-cookie");
		return axios.get(url, headers);
	},

	get(url)
	{
		url = this.cleanUrl(url);
		return axios.get(url, headers);
	},

	post(url, data)
	{
		url = this.cleanUrl(url);
		return axios.post(url, data, headers);
	},

	patch(url, data)
	{
		url = this.cleanUrl(url);
		return axios.patch(url, data, headers);
	},


	
	// ===== Helpers ===== //
	
	cleanUrl(url)
	{
		// Removing last / if it exists
		url = url.replace(/\/$/, "");

		// Making sure the url starts with http
		// (sets the base url to be the backend url if it doesn't exist)
		if(url.substr(0, 4) != "http") {
			url = backendBaseUrl + url;
		}

		return url;
	}
}

export default httpService;


//
// // Interceptors
//
// axios.interceptors.response.use(
// 	(response) => {
// 		switch (response.status) {
// 			case 401:
// 				router.push({ name: "login-page" });
// 				break;
// 		}
// 		return response;
// 	},
// 	(error) => {
// 		if (error.response) {
// 			switch (error.response.status) {
// 				case 401:
// 					router.push({ name: "login-page" });
// 					break;
// 				case 403:
// 					console.error(error.response.data);
// 					error.response.data.message =
// 						error.response.data.message != null &&
// 						error.response.data.message != ""
// 							? error.response.data.message
// 							: "Missing Permissions";
// 					break;
// 				case 500:
// 					console.error(error.response.data);
// 					error.response.data.message = "An unexpected error occured";
// 					break;
// 			}
// 			return Promise.reject(error.response.data);
// 		} else {
// 			switch(error.code){
// 				case "ERR_NETWORK":
// 					error.message = "Unable to connect to the network. Are you still connected to the internet? Please check your connection and try again."
// 			}
// 			return Promise.reject(error);
// 		}
// 	}
// );
//
// // Services
//
// var httpService = {
// 	post(api, data) {
// 		api = api.replace(/\/$/, ""); // Removing last "/" if it exists
// 		return axios.post(url + api, data, headers);
// 	},
// 	patch(api, data) {
// 		api = api.replace(/\/$/, ""); // Removing last "/" if it exists
// 		return axios.patch(url + api, data, headers);
// 	},
// 	get(api) {
// 		api = api.replace(/\/$/, ""); // Removing last "/" if it exists
// 		if (api.substr(0, "http".length) == "http") {
// 			return axios.get(api, headers);
// 		}
// 		return axios.get(url + api, headers);
// 	},
// 	download(api) {
// 		api = api.replace(/\/$/, ""); // Removing last "/" if it exists
// 		headers['responseType'] = 'blob';
//
// 		if (api.substr(0, "http".length) != "http") {
// 			api = url + api
// 		}
//
// 		return axios.get(api, headers)
// 			.then(response => {
//
// 				let blob = new Blob([response.data], { type: 'application/pdf' }),
// 					url = window.URL.createObjectURL(blob)
//
// 				window.open(url)
// 			});
// 	},
// 	delete(api) {
// 		api = api.replace(/\/$/, ""); // Removing last "/" if it exists
// 		return axios.delete(url + api, headers);
// 	},
// 	deleteToken() {
// 		delete axios.defaults.headers.common["Authorization"];
// 		delete axios.defaults.headers.common["Accept-Language"];
// 	},
// 	saveToken(token) {
//         axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
//     },
// };
// export default httpService;