import apiResourceStoreFactory from "./apiResourceStoreFactory";

let storeFactory = new apiResourceStoreFactory("POSITION", "POSITIONS", "/api/v1/positions/");
let store = storeFactory.getStore();

function buildGetRequest(storeKey, path) {
	store.actions[storeKey] = ({ state, dispatch, commit }) =>
		storeFactory.getApiRequest(
			state,
			dispatch,
			commit,
			storeKey,
			storeFactory.baseApiUrl + path
		);
	store.state[storeKey] = storeFactory.initStateResponseObj();
	store.getters[storeKey] = (state) => state[storeKey];
}

buildGetRequest("GET_POSITIONS_GROUPED_BY_COMPANY", "by/company");

export default store;
