<template>
	<v-footer inset width="auto" dark app>
		<v-container>
			<v-row justify="center">
				<v-col cols="6" sm="4">
					Andrew Lampert
					<br />
					<v-btn x-small :href="'mailto:' + email" color="secondary" outlined>
						<v-icon left>mdi-email</v-icon>
						{{ email }}
					</v-btn>
				</v-col>
				<v-col cols="6" sm="4" class="text-center">
					{{ appName }} <br />
					Version {{ version }}
				</v-col>
				<!-- <v-col cols="12" sm="4" class="text-right"> -->
				<!-- Small nav -->
				<!-- </v-col> -->
			</v-row>
		</v-container>
	</v-footer>
</template>

<script>
import rootPackage from "@/../../package.json";
export default {
	data() {
		return {
			appName: process.env.VUE_APP_NAME,
			email: process.env.VUE_APP_CONTACT_EMAIL,
			version: rootPackage.version,
			showFooter: process.env.VUE_APP_SHOW_FOOTER ?? false,
		};
	},
};
</script>

<style></style>
