<template>
	<TeaserCard
		:body="body"
		:title="title"
		:icons="['mdi-language-javascript', 'mdi-language-php', 'mdi-database']"
		:loading="this.$store.getters.GET_METRICS.loading"
	/>
</template>
<script>
import TeaserCard from "@/components/teasers/cards/TeaserCard.vue";
export default {
	components: {
		TeaserCard
	},
	data() {
		return {
			title: "Fullstack Web Development",
		}
	},
	mounted() {
		this.$store.dispatch("GET_METRICS");
	},
	computed: {
		experience: function()
		{
			if(this.$store.getters.GET_METRICS.loaded == false){
				return "0 seconds";
			}
			else {
				return this.$store.getters.GET_METRICS.data.experience.employee_fullstack
			}
		},
		body: function()
		{
			return `I have ${this.experience} of professional fullstack web development experience.`;
		}
	},
}
</script>
