<template>
	<v-row>
		<v-col class="d-flex justify-space-around">
			<FullstackDeveloperTeaserCard />
		</v-col>
		<v-col class="d-flex justify-space-around">
			<LeadershipTeaserCard />
		</v-col>
	</v-row>
</template>
<script>
import FullstackDeveloperTeaserCard from "@/components/teasers/cards/FullstackDeveloperTeaserCard.vue";
import LeadershipTeaserCard from "@/components/teasers/cards/LeadershipTeaserCard.vue";
export default {
	components: {
		FullstackDeveloperTeaserCard,
		LeadershipTeaserCard,
	}
}
</script>
