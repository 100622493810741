<template>
	<div class="text-center ma-2">
		<v-snackbar v-model="showToast" top :color="$store.getters.popup.color">
			{{ $store.getters.popup.message }}

			<template v-slot:action="{ attrs }">
				<v-btn
					text
					v-bind="attrs"
					@click="showToast = false"
				>
					Close
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>

<script>
export default {
	computed: {
		showToast: {
			get() {
				return this.$store.getters.popup.show;
			},
			set(show) {
				this.$store.dispatch("SET_POPUP", {
					show: show,
				});
			},
		},
	},
};
</script>

<style></style>
